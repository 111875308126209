<template>
  <!DOCTYPE html>
  <html lang="uk">

  <head>
    <meta charset="UTF-8">
    <link href="https://fonts.googleapis.com/css2?family=Inter&display=swap" rel="stylesheet">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content=" Legal address: Ekskavatorna Str., 37, Kyiv, 03062, Ukraine
        Warehouse and Shipping: Novomoskovsk, Suchkova St., 117, 51200
        Phone: +38 097 444 57 12,
        Email: order@gmsteel.com.ua">
    <title>GMSteel</title>

  </head>


  <FirstPage></FirstPage>
  <IntroSection :points="tm('intro_ul')" :intro="t('intro')" :additionalInfo="t('additionalInfo')" />
  <div class="">



    <section class="swag intro" id="material">
      <h2 class="display-5">{{ t('materialinfo') }}</h2>
    </section>

    <section class="materials">
      <MaterialList :material="t('materialinfo')" :materials="tm('materialslist')" />
    </section>

    <section class="swag2 intro" id="services">
      <h2 class="display-5">{{ t('services') }}</h2>
    </section>


    <section class="services">
      <ServiceList :points="tm('services_ul')" :service="t('services')" />
    </section>

    <section>
      <Contacts :points="tm('contacts_ul')" :service="t('contacts')" />
    </section>

    <!-- <section>
      <h3>4. Пошук по сайту</h3>
      
    </section>

    <section>
      <h3>5. Зворотній зв’язок</h3>
     
    </section> -->

  </div>

  </html>
</template>


<script>
import { watch } from "vue";
import { useI18n } from "vue-i18n";
import FirstPage from "./components/FirstPage.vue";
import MaterialList from "@/components/MaterialList.vue";
import IntroSection from '@/components/IntroSection.vue';
import ServiceList from "@/components/ServicesComponent.vue";
import Contacts from "@/components/ContactsComponent.vue";

export default {
  name: "App",
  components: {
    FirstPage,
    MaterialList,
    IntroSection,
    ServiceList,
    Contacts
  },
  setup() {
    const { t, tm, locale } = useI18n();

    watch(locale, (newlocale) => {
      localStorage.setItem("locale", newlocale);
    });

    return {
      t, tm,
      locale,
    };
  },
  data() {
    return {
      scrolled: false,
      intros: this.tm("intro_ul"),
      materialslist: this.t.materials,
      buttonText: this.$i18n.locale,
      video: require('@/assets/video.mp4')
    };

  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    toggleText() {
      const newLocale = this.$i18n.locale === 'en-US' ? 'ua-UA' : 'en-US';
      this.locale = newLocale;
    },
    handleScroll() {
      this.scrolled = window.scrollY > 50;
    },
    toggleNavbar() {
      this.isNavbarOpen = !this.isNavbarOpen;
    }
  }
};
</script>

<style>
#app {
  font-family: 'gothik', 'Inter', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
  color: black;

}

@font-face {
  font-family: 'gothik';
  src: url('../public/fonts/gotham_black.otf') format('opentype');
}

ul,
ol {
  list-style-type: none;
}


.background {

  background-color: rgba(59, 130, 246, 0.10);
  background-size: cover;

}

.no-select {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Standard syntax */
}



@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0)
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}



.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animatedFadeInUp {
  opacity: 0
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(60px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-in-left1 {
  animation: fadeInLeft 0.5s ease-out 0.5s forwards;
}

.fade-in-left2 {
  animation: fadeInLeft 1s ease-out 1s forwards;
}

.swag {
  background: rgba(255, 255, 255, 0.7) url(../src/assets/material.webp) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  padding: 200px 0;
  color: white;
  text-transform: uppercase;
  letter-spacing: 5px;
}

.swag2 {
  background: rgba(255, 255, 255, 0.7) url(../src/assets/services.webp) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  padding: 200px 0;
  color: white;
  text-transform: uppercase;
  letter-spacing: 5px;
}
</style>
