<template>
    <svg :class="{ 'svg-scroled': scrolled }" fill="#fff" width="300px" height="50px" viewBox="0 0 300 50" version="1.1"
        id="SVGRoot" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">
        <defs id="defs1292">
            <clipPath clipPathUnits="userSpaceOnUse" id="clipPath16">
                <path d="M 0,560 H 960 V 0 H 0 Z" id="path14" />
            </clipPath>
        </defs>
        <g id="layer1">
            <g id="g458" transform="matrix(0.47643956,0,0,-0.47643957,-98.457356,98.447886)">
                <g id="g456" transform="translate(41.840354,-150.13303)">
                    <g id="g454" clip-path="url(#clipPath16)">
                        <g id="g388" transform="translate(234.0479,339.4326)">
                            <path
                                d="m 0,0 c 0,-8.413 -6.82,-15.234 -15.234,-15.234 -8.413,0 -15.234,6.821 -15.234,15.234 0,8.413 6.821,15.234 15.234,15.234 C -6.82,15.234 0,8.413 0,0"
                                style="fill-opacity:1;fill-rule:nonzero;stroke:none" id="path386" />
                        </g>
                        <g id="g392" transform="translate(203.5802,327.7039)">
                            <path
                                d="m 0,0 c 0,-7.073 -5.734,-12.807 -12.807,-12.807 -7.073,0 -12.807,5.734 -12.807,12.807 0,7.073 5.734,12.807 12.807,12.807 C -5.734,12.807 0,7.073 0,0"
                                style="fill-opacity:1;fill-rule:nonzero;stroke:none" id="path390" />
                        </g>
                        <g id="g396" transform="translate(190.7729,301.0334)">
                            <path
                                d="m 0,0 c 0,-6.589 -5.342,-11.931 -11.931,-11.931 -6.589,0 -11.931,5.342 -11.931,11.931 0,6.589 5.342,11.931 11.931,11.931 C -5.342,11.931 0,6.589 0,0"
                                style="fill-opacity:1;fill-rule:nonzero;stroke:none" id="path394" />
                        </g>
                        <g id="g400" transform="translate(200.9451,273.2539)">
                            <path
                                d="m 0,0 c 0,-5.452 -4.42,-9.872 -9.872,-9.872 -5.452,0 -9.872,4.42 -9.872,9.872 0,5.452 4.42,9.872 9.872,9.872 C -4.42,9.872 0,5.452 0,0"
                                style="fill-opacity:1;fill-rule:nonzero;stroke:none" id="path398" />
                        </g>
                        <g id="g404" transform="translate(226.6753,262.0963)">
                            <path
                                d="m 0,0 c 0,-4.516 -3.661,-8.177 -8.177,-8.177 -4.516,0 -8.177,3.661 -8.177,8.177 0,4.516 3.661,8.177 8.177,8.177 C -3.661,8.177 0,4.516 0,0"
                                style="fill-opacity:1;fill-rule:nonzero;stroke:none" id="path402" />
                        </g>
                        <g id="g408" transform="translate(252.3825,273.2395)">
                            <path
                                d="m 0,0 c 0,-3.276 -2.656,-5.932 -5.932,-5.932 -3.276,0 -5.932,2.656 -5.932,5.932 0,3.276 2.656,5.932 5.932,5.932 C -2.656,5.932 0,3.276 0,0"
                                style="fill-opacity:1;fill-rule:nonzero;stroke:none" id="path406" />
                        </g>
                        <g id="g412" transform="translate(261.8194,301.011)">
                            <path
                                d="m 0,0 c 0,-2.234 -1.811,-4.044 -4.044,-4.044 -2.234,0 -4.045,1.81 -4.045,4.044 0,2.234 1.811,4.044 4.045,4.044 C -1.811,4.044 0,2.234 0,0"
                                style="fill-opacity:1;fill-rule:nonzero;stroke:none" id="path410" />
                        </g>
                        <g id="g416" transform="translate(248.0685,327.8387)">
                            <path
                                d="m 0,0 c 0,-1.266 -1.026,-2.292 -2.292,-2.292 -1.266,0 -2.292,1.026 -2.292,2.292 0,1.266 1.026,2.292 2.292,2.292 C -1.026,2.292 0,1.266 0,0"
                                style="fill-opacity:1;fill-rule:nonzero;stroke:none" id="path414" />
                        </g>
                        <g id="g420" transform="translate(320.4922,289.5312)">
                            <path
                                d="M 0,0 6.992,35.977 H -3.242 L -6.25,26.836 C -10.287,14.596 -14.311,5.65 -18.32,0 Z m 11.133,0 h 7.226 L 13.398,-25.469 H 3.633 l 3.086,15.938 h -35.313 l -3.086,-15.938 h -9.726 L -36.445,0 h 6.054 c 2.318,3.15 4.707,7.193 7.168,12.129 2.461,4.934 4.655,10.267 6.582,15.996 l 5.782,17.383 h 30.82 z"
                                style="fill-opacity:1;fill-rule:nonzero;stroke:none" id="path418" />
                        </g>
                        <g id="g424" transform="translate(369.6719,280)">
                            <path
                                d="m 0,0 4.609,23.633 c -1.901,0 -3.385,0.377 -4.453,1.133 L -18.203,0 H -30.508 L -7.07,30.195 c -0.756,0.833 -2.201,3.815 -4.336,8.946 l -6.719,15.898 h 11.328 l 4.883,-12.656 c 1.562,-4.089 2.903,-6.902 4.023,-8.438 1.12,-1.537 2.461,-2.304 4.024,-2.304 l 4.531,23.398 H 21.367 L 16.836,31.641 c 1.25,0 2.623,0.664 4.121,1.992 1.497,1.328 4.017,4.244 7.559,8.75 l 9.961,12.656 H 50.586 L 37.93,39.141 C 36.705,37.578 35.24,35.872 33.535,34.023 31.829,32.174 30.572,30.989 29.766,30.469 L 41.602,0 H 29.648 l -8.75,25 c -0.573,-0.313 -1.452,-0.619 -2.636,-0.918 -1.186,-0.3 -2.182,-0.449 -2.989,-0.449 L 10.664,0 Z"
                                style="fill-opacity:1;fill-rule:nonzero;stroke:none" id="path422" />
                        </g>
                        <g id="g428" transform="translate(418.1094,280)">
                            <path d="M 0,0 10.664,55.039 H 22.187 L 11.484,0 Z"
                                style="fill-opacity:1;fill-rule:nonzero;stroke:none" id="path426" />
                        </g>
                        <g id="g432" transform="translate(455.8828,280)">
                            <path
                                d="M 0,0 10.664,55.039 H 53.945 L 52.227,46.133 H 20.469 L 17.773,32.305 H 47.148 L 45.43,23.398 H 16.055 L 13.242,8.906 h 33.36 L 44.844,0 Z"
                                style="fill-opacity:1;fill-rule:nonzero;stroke:none" id="path430" />
                        </g>
                        <g id="g436" transform="translate(554.9453,280)">
                            <path
                                d="m 0,0 6.719,34.531 c 0.677,3.385 1.64,7.578 2.89,12.578 L 6.914,41.211 C 4.856,36.68 3.372,33.567 2.461,31.875 L -13.633,0 h -8.203 l -3.672,31.992 c -0.807,7.578 -1.328,12.617 -1.562,15.117 -0.73,-6.354 -1.289,-10.507 -1.68,-12.461 L -35.469,0 H -45.703 L -35,55.039 h 15.43 l 3.437,-30.781 0.977,-10.781 c 0.937,2.083 1.809,3.97 2.617,5.664 0.807,1.692 6.849,13.658 18.125,35.898 H 20.937 L 10.234,0 Z"
                                style="fill-opacity:1;fill-rule:nonzero;stroke:none" id="path434" />
                        </g>
                        <g id="g440" transform="translate(605.8076,302.3828)">
                            <path
                                d="m 0,0 c 0,-4.506 1.113,-7.988 3.34,-10.449 2.226,-2.461 5.41,-3.692 9.551,-3.692 6.926,0 12.369,3.36 16.328,10.079 l 8.867,-4.571 c -5.469,-9.687 -14.128,-14.531 -25.977,-14.531 -4.974,0 -9.264,0.937 -12.871,2.812 -3.607,1.875 -6.342,4.551 -8.203,8.028 -1.862,3.476 -2.793,7.532 -2.793,12.168 0,6.562 1.367,12.427 4.102,17.597 2.734,5.169 6.555,9.134 11.465,11.895 4.908,2.76 10.488,4.141 16.738,4.141 5.989,0 10.904,-1.284 14.746,-3.848 3.841,-2.566 6.426,-6.387 7.754,-11.465 L 32.344,15.391 c -0.756,2.786 -2.201,4.986 -4.336,6.601 -2.136,1.614 -4.74,2.422 -7.813,2.422 -6.302,0 -11.244,-2.187 -14.824,-6.562 C 1.79,13.477 0,7.525 0,0"
                                style="fill-opacity:1;fill-rule:nonzero;stroke:none" id="path438" />
                        </g>
                        <g id="g444" transform="translate(679.8311,326.1328)">
                            <path
                                d="M 0,0 -8.984,-46.133 H -20.508 L -11.523,0 h -17.774 l 1.719,8.906 H 19.531 L 17.812,0 Z"
                                style="fill-opacity:1;fill-rule:nonzero;stroke:none" id="path442" />
                        </g>
                        <g id="g448" transform="translate(695.1436,280)">
                            <path d="M 0,0 10.664,55.039 H 22.187 L 11.484,0 Z"
                                style="fill-opacity:1;fill-rule:nonzero;stroke:none" id="path446" />
                        </g>
                        <g id="g452" transform="translate(759.3232,325.5078)">
                            <path
                                d="m 0,0 h -15.898 l -4.532,-13.594 c -2.943,-8.724 -5.221,-14.902 -6.836,-18.535 -1.615,-3.633 -3.171,-6.433 -4.668,-8.398 -1.498,-1.967 -3.164,-3.393 -5,-4.278 -1.836,-0.884 -4.082,-1.328 -6.738,-1.328 -1.484,0 -2.981,0.222 -4.492,0.664 l 1.875,9.649 c 0.729,-0.183 1.445,-0.274 2.148,-0.274 1.302,0 2.487,0.697 3.555,2.09 1.067,1.393 2.35,3.919 3.848,7.578 1.497,3.658 3.599,9.486 6.308,17.481 l 6.211,18.476 H 12.969 L 2.266,-45.508 H -8.828 Z"
                                style="fill-opacity:1;fill-rule:nonzero;stroke:n.svglogoone" id="path450" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>
<script>



export default {
    props: {
        scrolled: {
            type: String,
            required: true,
        }
    }
};
</script>

<style scoped>
.svg-scroled {
    fill: #b30404;
}
</style>
