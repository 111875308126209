<template>
  <div class="container my-5 mx-6 cd">
    <div class="card text-dark bg-light ">
      <!-- <img src="..." class="card-img" alt="..."> -->
      <div class="card-img-overlay d-flex flex-column justify-content-around">
        <div class="card-title fs-3"><strong>{{ material.name }} (Hardness ~ {{ material.hardness }} HRC)</strong></div>
        <ul>
          <li class="fs-6 " v-for="point in material.points" :key="point">{{ point }}</li>
        </ul>

        <div class="apl">{{ material.application }}</div>
        <div class="container">
          <span class="card-text" v-for="(value, element) in material.chemicalComposition" :key="element">
            <span class="fs-4 formula">{{ element }} - {{ value }} |</span> <span> </span>
          </span>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    material: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.card {
  border: 0.5rem solid transparent;
  border-radius: 1rem;
  color: hsl(0 0% 10%);
  height: 700px;
  --shadow:
    -.5rem -.5rem 1rem hsl(0 0% 100% / .75),
    .5rem .5rem 1rem hsl(0 0% 50% / .5);
  box-shadow: var(--shadow);
  outline: none;
  transition: all 0.1s;

  &:hover,
  &:focus-visible {
    color: hsl(10 80% 50%);
    scale: 1.1
  }

  &:active,
  &.active {
    box-shadow:
      var(--shadow),
      inset .5rem .5rem 1rem hsl(0 0% 50% / .5),
      inset -.5rem -.5rem 1rem hsl(0 0% 100% / .75);
    color: hsl(10 80% 50%);

    >i {
      font-size: 28cqi
    }

    ;

    >span {
      font-size: 13cqi
    }

    ;
  }

  >i {
    font-size: 31cqi;
  }

  >span {
    font-family: system-ui, sans-serif;
    font-size: 16cqi;
  }
}

/* Ваши стили для этого компонента */
.apl {
  text-align: justify;
}

.cd {
  width: 80%;
}

hr {
  width: 30%;
  margin: auto;
}
@media (max-width: 768px) {
  .cd {
    width: 100%; /* Full width on smaller screens */
  }
  .card-title, .formula{
    font-size: 14px !important;
  }
  .apl, li{
    font-size: 12px !important;
  }
}
</style>
