<template>
   <nav class="navbar navbar-expand-sm">
  <div class="container-fluid">
    <a class="navbar-brand" href="#"></a>
    <ul class="navbar-nav">
        <li class="nav-item">
            <a class="nav-link" href="#" aria-label="home page">{{ t('home') }}</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="#material" aria-label="material info">{{ t('materialinfo') }}</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="#services" aria-label="services info">{{ t('services') }}</a>
        </li>
        <li class="nav-item">
            <div class="nav-link no-select" @click="toggleText">{{ t('lang') }}</div>
        </li>
    </ul>
  </div>
</nav>
 
</template>

<script>
import { watch } from "vue";
import { useI18n } from "vue-i18n";


export default {
 
setup() {
        const { t, tm, locale } = useI18n();

        watch(locale, (newlocale) => {
            localStorage.setItem("locale", newlocale);
        });

        return {
            t, tm,
            locale,
        };
    },
    methods: {
    toggleText() {
      const newLocale = this.$i18n.locale === 'en-US' ? 'ua-UA' : 'en-US';
      this.locale = newLocale;
    }}
}
  
</script>

<style>
.navbar {
    background-color: rgba(230, 227, 227, 0);
    text-transform: uppercase;
}
</style>