<template>
    <div class="container-fluid background ">
        <div class="row">
            <div class="col-sm-6">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d848.7979953685552!2d35.19065447223054!3d48.607906532021225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d95b0066ad929b%3A0xc37c866e76abbf89!2z0KLQntCSICLQlNCW0IYg0JXQnCDQodCi0IbQmyI!5e0!3m2!1sru!2sua!4v1707507319275!5m2!1sru!2sua"
                    width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>

            </div>
            <div class="col-sm-6 text-right d-flex flex-column align-items-stretch">

                <div class="">
                    <MiniLink></MiniLink>
                </div>
                <div class="mt-auto">
                    <h2>{{ contacts }}</h2>
                    <div class="mb-3">
                        <div v-for="(point, index) in points" :key="index">
                            <p class="fs-4">{{ point }}</p>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
    <!-- <div class="background">

        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d2638.0811315849323!2d35.191682181031!3d48.60829026603013!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1suk!2sua!4v1707333612391!5m2!1suk!2sua" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        <div class="container py-5">
            <h2>{{ contacts }}</h2>
            <div class="mb-3">
                <div v-for="(point, index) in points" :key="index">
                    <p class="fs-4">{{ point }}</p>
                </div>
            </div>
        </div>
    </div> -->
</template>
  
<script>
import MiniLink from './MiniLink.vue';
export default {
    name: 'ServiceComponrnt',
    props: {
        points: {
            type: Array,
            required: true,
        },
        contacts: {
            type: String
        }

    },
    components: {
        MiniLink
    }
};
</script>
<style>
.background {
    /* background-image: url('~@/assets/1.png');  rgb(59 130 246 / 0.5)*/
    background-color: rgba(59, 130, 246, 0.10);
    width: 100%;

    /* Другие свойства стиля */
}

.text-right {
    text-align: right;
}</style>