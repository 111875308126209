

<template>
        <section class="intro-container">
        <div class="intro-background">
            <!-- Весь ваш текущий код остается неизменным -->
            <h2 class="fs-3">{{ intro }}</h2>
            <div class="intro-content">
                <div class=" fs-3 mb-5">
                    <div class="mb-5" v-for="(point, index) in points" :key="index"><p class="fs-3">{{ point }}</p></div>
                </div>
                <!-- <p class="fs-3">* {{ additionalInfo }}</p> -->
            </div>
        </div>
    </section>
</template>
  
<script>
export default {
    name: 'IntroSection',
    props: {
        points: {
            type: Array,
            required: true,
        },
        intro: {
            type: String
        },
        additionalInfo: {
            type: String
        }

    },
};
</script>
<style scoped>
  p {
    white-space: pre-line;
  }
.intro-container {
    position: relative;
    /* Убираем отступы у секции для максимального использования пространства экрана */
    margin: 0;
    padding: 0;
}

.intdro-background {
    /* Добавляем фоновое изображение на весь экран */
    background-image: url(../../src/assets/pila.webp);
    background-size: cover; /* Растягиваем изображение на весь экран */
    background-position: center; /* Центрируем изображение */
    height: 100vh; /* Занимаем 100% высоты экрана */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff; /* Цвет текста, чтобы он был виден на фоне */
}

.intro-background {
    background: rgba(255, 255, 255, 0.7) url(../../src/assets/cubes3.jpg) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    padding: 200px 0;
    color: white;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
    /* text-transform: uppercase;
    letter-spacing: 5px; */
  }
</style> 