<template>
  <div >
    <!-- Отображение выбранного материала -->
    <h2>{{ material }}</h2>

    <!-- Вывод списка материалов с использованием MaterialComponent -->
    <div class="material my-5 mx-5">
      <div class="row gx-3">
        <div class="col-md-6">
<MaterialComponent v-for="materialItem in firstColumn" :key="materialItem.name" :material="materialItem" />
        </div>
        <div class="col-md-6">
<MaterialComponent v-for="materialItem in secondColumn" :key="materialItem.name" :material="materialItem" />
        </div>
      </div>
      
    </div>
  
  </div>
</template>

<script>
import MaterialComponent from "@/components/MaterialComponent.vue";

export default {
  components: {
    MaterialComponent,
  },
  props: {
    // Переименование props для более ясного понимания
    materials: {
      type: Array,
      required: true,
    },
    selectedMaterial: {
      type: String,
    },
  },
  computed: {
    // Разделение массива на две части
    firstColumn() {
      return this.materials.slice(0, Math.ceil(this.materials.length / 2));
    },
    secondColumn() {
      return this.materials.slice(Math.ceil(this.materials.length / 2));
    },
  },
};
</script>

<style scoped>
/* Ваши стили для этого компонента */


</style>


<!-- 
<template>
  <div>
  
      <h2>{{ material }}</h2>
      <div class="material"> <MaterialComponent v-for="material in materials" :key="material.name" :material="material" /></div>
     
  
  </div>
</template>
  
<script>
import MaterialComponent from "@/components/MaterialComponent.vue";

export default {
  components: {
    MaterialComponent,
  },
  props: {
    materials: {
      type: Array,
      required: true,
    },
    material: {
      type: String
    }
  },
};
</script>
  
<style scoped>
.material {



}

/* Ваши стили для этого компонента */</style> -->