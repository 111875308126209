<template>
    <NavBar></NavBar>
    <div class="frsstpage">
        <div class="bg-video-wrap">
            <video :src="video" loop muted autoplay>
            </video>
            <div class="overlay">
            </div>
            <div class="ctext container text-center">
                <h2 class="display-2 animated animatedFadeInUp fadeInUp">{{ t("sl1") }}</h2>
                <h3 class="display-2 lead animated animatedFadeInUp fadeInUp">{{ t("sl2") }}</h3>
            </div>
        </div>
    </div>
    <div>
    </div>
</template>    
<script>
import { watch } from "vue";
import { useI18n } from "vue-i18n";
import NavBar from "./NavBar.vue";
export default {
    components: {
        NavBar


    },
    setup() {
        const { t, tm, locale } = useI18n();

        watch(locale, (newlocale) => {
            localStorage.setItem("locale", newlocale);
        });

        return {
            t, tm,
            locale,
        };
    },
    data() {
        return {
            scrolled: false,
            intros: this.tm("intro_ul"),
            materialslist: this.t.materials,
            buttonText: this.$i18n.locale,
            video: require('@/assets/video.mp4')
        };

    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        toggleText() {
            const newLocale = this.$i18n.locale === 'en-US' ? 'ua-UA' : 'en-US';
            this.locale = newLocale;
        },
        handleScroll() {
            this.scrolled = window.scrollY > 50;
        },
        toggleNavbar() {
            this.isNavbarOpen = !this.isNavbarOpen;
        }
    }
};
</script>

<style scoped>
@font-face {
    font-family: 'gothik';
    src: url('../../public/fonts/gotham_black.otf') format('opentype');
}



.no-select {
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Standard syntax */
}






.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}

.animatedFadeInUp {
    opacity: 0
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translateX(60px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.fade-in-left1 {
    animation: fadeInLeft 0.5s ease-out 0.5s forwards;
}

.fade-in-left2 {
    animation: fadeInLeft 1s ease-out 1s forwards;
}


.bg-video-wrap {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    background: url(../../src/assets/overlay.png) no-repeat center center/cover;

}

video {
    min-width: 100%;
    min-height: 100vh;
    z-index: 1;
}

.overlay {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(45deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, .4) 50%);
    background-size: 3px 3px;
    z-index: 2;
}

h1 {
    text-align: center;
    color: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 3;
    max-width: 400px;
    width: 100%;
    height: 50px;
}

.ctext {
    padding-top: 40px;
    text-align: center;
    color: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 3;
    /* max-width: 800px; */
    width: 100%;
    height: 50px;
}
@media (max-width: 768px) {
    .ctext{
         max-width: 400px;
    }
}
</style>
