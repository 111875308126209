import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import ua from './language/uk-UA.json'
import en from './language/en-US.json'
import 'bootstrap/dist/css/bootstrap.min.css';

const i18n = createI18n({
    legacy: false,
    seo: true,
    locale: localStorage.getItem("locale") ?? "uk-UA",
    fallbackLocale: "uk-UA",
    messages: {
      "uk-UA": ua,
      "en-US": en
      
    }
  });
  

createApp(App).use(i18n).mount('#app')
import 'bootstrap/dist/js/bootstrap.js';