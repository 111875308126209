<template>
    <section class="intro container my-5">
        <!-- <h2>{{ service }}</h2> -->
        <div class="mb-3 my-3 card ">
            <div class="mb-3" v-for="(point, index) in points" :key="index"><p class="fs-4 text-end">{{ point }}</p></div>
        </div>
    </section>
</template>
  
<script>
export default {
    name: 'ServiceComponrnt',
    props: {
        points: {
            type: Array,
            required: true,
        },
        service: {
            type: String
        }

    },
};
</script>
<style scoped> p {
    white-space: pre-line;
    font-weight: normal;
  }
  .card {
    height: 100% !important;
  border: 0.5rem solid transparent;
  border-radius: 1rem;
  color: hsl(0 0% 10%);
  height: 700px;
  --shadow:
    -.5rem -.5rem 1rem hsl(0 0% 100% / .75),
    .5rem .5rem 1rem hsl(0 0% 50% / .5);
  box-shadow: var(--shadow);
  outline: none;
  transition: all 0.1s;

  &:hover,
  &:focus-visible {
    /* color: hsl(10 80% 50%); */
    scale: 1.1
  }

  &:active,
  &.active {
    box-shadow:
      var(--shadow),
      inset .5rem .5rem 1rem hsl(0 0% 50% / .5),
      inset -.5rem -.5rem 1rem hsl(0 0% 100% / .75);
    /* color: hsl(10 80% 50%); */

    >i {
      font-size: 28cqi
    }

    ;

    >span {
      font-size: 13cqi
    }

    ;
  }

  >i {
    font-size: 31cqi;
  }

  >span {
    font-family: system-ui, sans-serif;
    font-size: 16cqi;
  }
}
  
  </style>