<template>
    <div class="mynavbar">
        <nav class="navbar navbar-expand-lg navbar-dark fixed-top" :class="{ 'navbar-scrolled': scrolled }">
            <div class="container">

                <a class="navbar-brand" href="#" aria-label="home page">
                    <LogoComponent :scrolled="scrolled"></LogoComponent>
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" @click="toggleNavbar">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" :class="{ 'show': isNavbarOpen }" id="navbarNav">
                    <ul class="navbar-nav ms-auto">
                        <li class="nav-item">
                            <a class="nav-link" href="#" aria-label="home page">{{ t('home') }}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#material" aria-label="material info">{{ t('materialinfo') }}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#services" aria-label="services info">{{ t('services') }}</a>
                        </li>
                        <li class="nav-item">
                            <div class="nav-link no-select" @click="toggleText">{{ t('lang') }}</div>
                        </li>
                        <li></li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>
<script>
import { watch } from "vue";
import { useI18n } from "vue-i18n";
import LogoComponent from "./LogoComponent.vue";

export default {

    components: {
        LogoComponent
    },
    setup() {
        const { t, tm, locale } = useI18n();
        watch(locale, (newlocale) => {
            localStorage.setItem("locale", newlocale);
        });
        return {
            t, tm,
            locale,
        };
    },
    data() {
        return {
            scrolled: false,
            buttonText: this.$i18n.locale,

        };

    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        toggleText() {
            const newLocale = this.$i18n.locale === 'en-US' ? 'ua-UA' : 'en-US';
            this.locale = newLocale;
        },
        handleScroll() {
            this.scrolled = window.scrollY > 50;
        },
        toggleNavbar() {
            this.isNavbarOpen = !this.isNavbarOpen;
        }
    }
};
</script>

<style scoped>
.navbar {
    background-color: rgba(230, 227, 227, 0);
    text-transform: uppercase;
}

.navbar-scrolled {
    background-color: rgba(230, 227, 227, 0.5);
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
}

.nav-link {
    color: #ffffff !important;
}
</style>
